import styled from 'styled-components';

import { colors } from '../../utils/colors';

export default styled.a.attrs({
  rel: 'noopener',
  target: '_blank',
})`
  color: ${colors.text.primary};
`;
