import { windowExists } from '../window';

export type Color = string | Palette;

export interface Palette {
  [key: string]: Color;
}

export interface Colors extends Palette {
  box: {
    main: string,
    background: string,
    foreground: string,
    shadow: string,
    shadow_active: string,
    border: string,
  },
  components: {
    menu: {
      background: string,
    },
    inline_code: {
      background: string,
      color: string,
    },
    scroll_indicator: {
      color: string,
    }
  },
  text: {
    active: string,
    default: string,
    grey: string,
    primary: string,
    quote: string,
    secondary: string,
    slight: string,
  },
}

export const tagColors = {
  css: '#f09ec6',
  gatsby: '#663399',
  jest: '#15c213',
  react: '#62dafb',
  rtl: '#573ede',
};

export const baseColors = {
  black: '#000000',
  blue_electric: '#68ebca',
  blue_night: '#025a8e',
  cream: '#ffcc8a',
  grey_dark: '#303c49',
  white: '#ffffff',
  yellow: '#ffd400',
};

const parseHexColors = (color: string) => /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);

const isRGBColor = (color: string) => /^rgb/.test(color);

/**
 * Returns the hexadecimal representation of the provided color.
 *
 * @param color A string representing one of the following :
 * - an hexadecimal color
 * - a color name --text-default
 * - a color variable var(--text-default)
 */
const getColor = (color: string) => {
  if (parseHexColors(color) || isRGBColor(color)) return color;

  const stringBetweenParenthesis = color.match(/\(([^)]+)\)/);
  const colorName = stringBetweenParenthesis ? stringBetweenParenthesis[1] : color;

  return windowExists()
    ? getComputedStyle(document.documentElement).getPropertyValue(colorName)
    : baseColors.white;
};

export const hexToRgb = (hex: string) => {
  const hexColors = parseHexColors(hex);
  if (!hexColors) {
    throw new Error('Argument must be an hexadecimal color.');
  }

  return [
    parseInt(hexColors[1], 16),
    parseInt(hexColors[2], 16),
    parseInt(hexColors[3], 16),
  ];
};

export const withOpacity = (hex: string, opacity: number) => (
  `rgba(${hexToRgb(getColor(hex)).concat([opacity]).join(', ')})`
);

export const colorNames: Colors = {
  box: {
    main: '--box-main',
    background: '--box-background',
    foreground: '--box-foreground',
    shadow: '--box-shadow',
    shadow_active: '--box-shadow_active',
    border: '--box-border',
  },
  components: {
    menu: {
      background: '--components-menu-background',
    },
    inline_code: {
      background: '--components-inline_code-background',
      color: '--components-inline_code-color',
    },
    scroll_indicator: {
      color: '--components-scroll_indicator-color',
    },
  },
  text: {
    active: '--text-active',
    default: '--text-default',
    grey: '--text-grey',
    primary: '--text-primary',
    quote: '--text-quote',
    secondary: '--text-secondary',
    slight: '--text-slight',
  },
};

export const colors: Colors = {
  box: {
    main: 'var(--box-main)',
    background: 'var(--box-background)',
    foreground: 'var(--box-foreground)',
    shadow: 'var(--box-shadow)',
    shadow_active: 'var(--box-shadow_active)',
    border: 'var(--box-border)',
  },
  components: {
    menu: {
      background: 'var(--components-menu-background)',
    },
    inline_code: {
      background: 'var(--components-inline_code-background)',
      color: 'var(--components-inline_code-color)',
    },
    scroll_indicator: {
      color: 'var(--components-scroll_indicator-color)',
    },
  },
  text: {
    active: 'var(--text-active)',
    default: 'var(--text-default)',
    grey: 'var(--text-grey)',
    primary: 'var(--text-primary)',
    quote: 'var(--text-quote)',
    secondary: 'var(--text-secondary)',
    slight: 'var(--text-slight)',
  },
};

export const darkColors: Colors = {
  box: {
    main: baseColors.black,
    background: '#14181f',
    foreground: '#1b2029',
    shadow: '#101214',
    shadow_active: '#0b0c10',
    border: '#272829',
  },
  components: {
    menu: {
      background: withOpacity(baseColors.black, 0.95),
    },
    inline_code: {
      background: '#1b2029',
      color: baseColors.cream,
    },
    scroll_indicator: {
      color: baseColors.cream,
    },
  },
  text: {
    active: baseColors.white,
    default: '#d9e4ed',
    grey: '#949494',
    primary: '#00eaff',
    quote: '#bebebe',
    secondary: baseColors.cream,
    slight: '#424749',
  },
};

export const lightColors: Colors = {
  box: {
    main: baseColors.white,
    background: baseColors.white,
    foreground: '#f9f9f9',
    shadow: '#cdcdcd',
    shadow_active: '#cdcdcd',
    border: '#272829',
  },
  components: {
    menu: {
      background: withOpacity(baseColors.white, 0.95),
    },
    inline_code: {
      background: '#f4f4f4',
      color: baseColors.blue_night,
    },
    scroll_indicator: {
      color: baseColors.blue_night,
    },
  },
  text: {
    active: baseColors.black,
    default: '#303842',
    grey: '#707070',
    primary: '#0079cf',
    quote: '#696969',
    secondary: baseColors.blue_night,
    slight: '#424749',
  },
};

export const getTextColorForBackground = (
  hex: string,
  darkColor: string = lightColors.text.default,
  lightColor: string = darkColors.text.default
) => {
  const [red, green, blue] = hexToRgb(getColor(hex));
  return (red * 0.299 + green * 0.587 + blue * 0.114) > 120 ? darkColor : lightColor;
};
