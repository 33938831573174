import React, { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { Theme, ThemeContext } from '../../types/theme';
import { setLocalTheme, nextTheme, getInitialTheme, THEME_ATTRIBUTE } from '../../utils/theme';

type ThemeProviderProps = PropsWithChildren<{}>;

const ThemeProvider: FC<ThemeProviderProps> = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(getInitialTheme());

  const switchTheme = useCallback(() => {
    setTheme(nextTheme);
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute(THEME_ATTRIBUTE, theme);
    setLocalTheme(theme);
  }, [theme]);

  return (
    <StyledThemeProvider theme={{ theme, switchTheme } as ThemeContext}>
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
