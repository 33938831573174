import React, { FC, memo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

// @ts-ignore
import favicon_48x48 from '../../static/favicon/favicon-48x48.png';
// @ts-ignore
import favicon_96x96 from '../../static/favicon/favicon-96x96.png';
// @ts-ignore
import favicon_144x144 from '../../static/favicon/favicon-144x144.png';
import metadata from '../../config/metadata';
import { ArticlePages } from '../../types/article';
import { paths } from '../../constants/pages';
import { getMetadata } from '../../utils/metadata';
import { PageContext } from '../../types/pages';

export type SEODataProps = Pick<PageContext, 'description' | 'title'>;

const suffix = 'Antoine Quinquenel';

const getTitleTemplate = (title?: string) => {
  const isHomePage = title === metadata[paths.home].title;

  return title && !isHomePage ? `%s — ${suffix}` : suffix;
};

export const SEOData: FC<SEODataProps> = ({ description, title }: SEODataProps) => (
  <Helmet titleTemplate={getTitleTemplate(title)}>
    <title>{title || suffix}</title>
    {!!description && <meta name={'description'} content={description} />}
  </Helmet>
);

const SEO: FC = () => {
  const { pathname } = useLocation();
  const data: ArticlePages = useStaticQuery(graphql`
    {
      allMdx {
        nodes {
          fields {
            slug
          }
          frontmatter {
            description
            title
          }
        }
      }
    }
  `);

  const {
    allMdx: {
      nodes: articles,
    },
  } = data;

  /**
   * Only pages created from the "src/pages" directory have metadata in their context.
   * Articles have their metadata set in frontmatter inside the .mdx file.
   */
  const {
    description,
    title,
  } = getMetadata(metadata[pathname], articles, pathname) ?? {};

  return (
    <>
      <Helmet>
        <html lang={'en'} />
        <link rel={'icon'} type={'image/png'} href={favicon_48x48} />
        <link rel={'icon'} type={'image/png'} href={favicon_96x96} />
        <link rel={'icon'} type={'image/png'} href={favicon_144x144} />
      </Helmet>
      <SEOData description={description} title={title} />
    </>
  );
};

export default memo(SEO);
