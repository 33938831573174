import { Styled } from '../../types/common';

export const styledProps = <P extends Record<string, any>>(props: P): Styled<P> => (
  Object.keys(props).reduce(
    (acc, cur) => ({
      ...acc,
      [`_${cur}`]: props[cur],
    }),
    {} as Styled<P>
  )
);
