import React, { FC } from 'react';
import styled from 'styled-components';
import { GatsbyLinkProps } from 'gatsby';

import Link from '../link/link';
import Text, { TextProps } from '../text/text';
import { colors } from '../../utils/colors';
import { stopBubbling } from '../../utils/event';

const textClass = '__text';

type TextLinkOwnProps = {
  noBubble?: boolean,
};

export type TextLinkProps = TextProps & GatsbyLinkProps<{}> & TextLinkOwnProps;

const StyledLink = styled(Link)`
  display: inline-block;

  &:hover, &:active, &:focus {
    .${textClass} {
      color: ${colors.text.active};
    }
  }
`;

const TextLink: FC<TextLinkProps> = ({
  children,
  className,
  color,
  fill,
  primary,
  role,
  size,
  to,
  noBubble = false,
}: TextLinkProps) => (
  <StyledLink
    className={className}
    color={color}
    role={role}
    to={to}
    onClick={noBubble ? stopBubbling : undefined}
  >
    <Text className={textClass} color={color} fill={fill} primary={primary} size={size}>
      {children}
    </Text>
  </StyledLink>
);

export const GreyTextLink = styled(TextLink)`
  &:active, &:focus, &:hover .${textClass} {
    color: inherit;
  }
`;

export default TextLink;
