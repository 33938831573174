export const menu = {
  closed: '0',
  open: '999',
  button: '1000',
};

export const MENU_CLOSED = -1;
export const ARTICLE_SUMMARY_HIDDEN = -1;
export const ARTICLE_SUMMARY_BUTTON_HIDDEN = -1;
export const LINE_HIGHLIGHT = -1;

export const MENU_DESKTOP = 0;
export const ARTICLE_SUMMARY_VISIBLE = 0;

export const CODE = 1;

export const INSET_SHADOW = 2;

export const ARTICLE_SUMMARY_OPEN_MOBILE = 997;

export const ARTICLE_SUMMARY_BUTTON_VISIBLE = 998;

export const MENU_OPEN = 999;
export const MENU_BUTTON = MENU_OPEN + 1;
