import React from 'react';
import styled from 'styled-components';

import ExternalLink from '../../ui/external-link/external-link';
import { rem } from '../../utils/converters';
import Text from '../../ui/text/text';
import Emoji from '../emoji/emoji';

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)} 0;
`;

const Item = styled.span`
  display: flex;
  align-items: inherit;
  margin-top: ${rem(20)};
`;

const Footer = () => (
  <Container role={'contentinfo'}>
    <Item>
      <Text>{'Made with\xa0\xa0'}</Text>
      <Emoji emoji={'❤️'} label={'love'} size={10} />
      <Text>{'\xa0\xa0from\xa0'}</Text>
      <ExternalLink href={'https://goo.gl/maps/HwcvureKoVaEeVFa8'}>Saumur</ExternalLink>
    </Item>
  </Container>
);

export default Footer;
