import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { rem } from '../../utils/converters';
import { BASE_FONT_SIZE } from '../../constants/layout';

interface ContainerProps {
  _size: number,
}

const Container = styled.span<ContainerProps>`
  font-size: ${({ _size }) => rem(_size)};
`;

const Emoji: FC<EmojiProps> = ({ emoji, label, size = BASE_FONT_SIZE }: EmojiProps) => (
  <Container role={'img'} aria-label={label} _size={size}>{emoji}</Container>
);

export type EmojiProps = {
  emoji: string;
  label: string,
  size?: number,
};

export default memo(Emoji);
