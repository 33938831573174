import React, { FC, useContext } from 'react';
import styled, { css, ThemeContext as StyledThemeContext } from 'styled-components';

import { rem } from '../../utils/converters';
import { StylelessButton } from '../../utils/components';
import { Theme, ThemeContext, Themes } from '../../types/theme';
import { nextTheme, THEME_SELECTORS } from '../../utils/theme';
import { baseColors } from '../../utils/colors';
import { useTransition } from '../../utils/mixins';

const MOON_SHADOW = 7;
const BUTTON_SIZE = 40;
const INDICATOR_SIZE = BUTTON_SIZE / 2;
const INDICATOR_MARGIN = INDICATOR_SIZE / 2;

type IndicatorProps = {
  _theme: Theme
};

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${rem(20)};
`;

const Button = styled(StylelessButton)`
  position: relative;
  width: ${rem(BUTTON_SIZE)};
  height: ${rem(BUTTON_SIZE)};
`;

const indicatorStyle = css`
  ${THEME_SELECTORS.DARK} & {
    box-shadow: -${rem(MOON_SHADOW)} ${rem(MOON_SHADOW)} ${baseColors.cream};
    transform: rotate(0deg);
  }

  ${THEME_SELECTORS.LIGHT} & {
    box-shadow: ${rem(MOON_SHADOW)} -${rem(MOON_SHADOW)} ${baseColors.grey_dark};
    transform: rotate(180deg);
  }
`;

const Indicator = styled.span<IndicatorProps>`
  position: absolute;
  border-radius: 50%;
  background-color: transparent;
  width: ${rem(INDICATOR_SIZE)};
  height: ${rem(INDICATOR_SIZE)};
  top: ${rem(INDICATOR_MARGIN - MOON_SHADOW)};
  left: ${rem(INDICATOR_MARGIN + MOON_SHADOW)};

  ${indicatorStyle}
  ${useTransition()}
`;

const ThemeSwitch: FC = () => {
  const { switchTheme, theme } = useContext<ThemeContext>(StyledThemeContext);
  const buttonTitle = `Activate ${nextTheme(theme)} theme`;

  return (
    <Container>
      <Button onClick={switchTheme} aria-label={buttonTitle} title={buttonTitle}>
        <Indicator
          _theme={theme}
          aria-label={theme === Themes.dark ? 'A bright moon' : 'A dark moon'}
          role={'img'}
        />
      </Button>
    </Container>
  );
};

export default ThemeSwitch;
