import { keyframes } from 'styled-components';

import { rem } from '../converters';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
    margin-top: ${rem(-16)};
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
`;

export const fillBorderRadius = keyframes`
  0%, 80% {
    border-radius: 50%;
  }
  100% {
    border-radius: 0;
  }
`;
