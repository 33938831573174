import { Themes, Theme } from '../../types/theme';
import { colorNames, Color, darkColors, lightColors, Palette } from '../colors';
import { removeSpaces } from '../string';
import { windowExists } from '../window';

export const PREFERRED_THEME = 'preferred-theme';
export const THEME_ATTRIBUTE = 'data-theme';
export const DEFAULT_THEME = Themes.light;
export const THEME_SELECTORS = {
  DARK: `html[${THEME_ATTRIBUTE}='${Themes.dark}']`,
  LIGHT: `html[${THEME_ATTRIBUTE}='${Themes.light}']`,
};

export const themes = {
  [Themes.dark]: darkColors,
  [Themes.light]: lightColors,
};

const switcher = {
  [Themes.dark]: Themes.light,
  [Themes.light]: Themes.dark,
};

export const nextTheme = (prev: Theme) => switcher[prev];

export const setLocalTheme = (theme: Theme) => {
  if (windowExists()) {
    window.localStorage.setItem(PREFERRED_THEME, theme);
  }
};

export const getInitialTheme = () => (
  windowExists()
    ? window.localStorage.getItem(PREFERRED_THEME) as Theme
    : DEFAULT_THEME
);

/**
 * @argument
 * {
 *   box: {
 *     first,
 *     second,
 *     third,
 *   }
 * }
 *
 * @returns
 * [
 *   ['box', 'first],
 *   ['box', 'second'],
 *   ['box', 'third'],
 * ]
 */
const getColorsPaths = (colors: Palette): string[][] => (
  Object.keys(colors).reduce((paths: string[][], key) => {
    const value: Color = colors[key];

    if (typeof value === 'string') {
      return paths.concat([key]);
    }

    return paths.concat(
      getColorsPaths(value).map((colorPath: string | string[]) => (
        typeof colorPath === 'string' ? [key, colorPath] : [key, ...colorPath]
      ))
    );
  }, [])
);

const getColorValueByPath = (color: Palette, path: string[]): string => {
  const [first, ...rest] = path;
  const value: Color = color[first];

  return typeof value === 'string' ? value : getColorValueByPath(value, rest);
};

const generateThemeCSSVariables = (theme: Theme) => {
  const paths = getColorsPaths(colorNames);

  return paths.reduce((result: string, path: string[]) => {
    const variableName = path.reduce((acc, cur) => `${acc}-${cur}`, '-');
    return result.concat(`${variableName}:${getColorValueByPath(themes[theme], path)};`);
  }, '');
};

export const generateThemeStyle = () => removeSpaces(`
  html, ${THEME_SELECTORS.LIGHT} {
    ${generateThemeCSSVariables(Themes.light)}
  }

  ${THEME_SELECTORS.DARK} {
    ${generateThemeCSSVariables(Themes.dark)}
  }
`);
