// common
export const BASE_FONT_SIZE = 16;
export const LARGE_FONT_SIZE = 20;
export const BASE_LINE_HEIGHT = 2;

// App container
export const APP_CONTAINER_MAX_WIDTH = 800;

// Menu
export const MENU_HEIGHT = 80;

// ArticleSummary
export const TOGGLE_BUTTON_SIZE = 80;
