import React, { FC, memo } from 'react';
import styled from 'styled-components';

import { rem } from '../../utils/converters';
import { min, useTransition } from '../../utils/mixins';
import TextLink from '../../ui/text-link/text-link';
import { styledProps } from '../../utils/object';
import { Styled } from '../../types/common';

type MenuItemProps = {
  index: number,
  link: string,
  open: boolean,
  title: string,
};

type LinkProps = Styled<Pick<MenuItemProps, 'index' | 'open'>>;

const StyledLink = styled(TextLink)<LinkProps>`
  ${({ _index }) => useTransition({ delay: _index * 0.1 })}

  padding: ${rem(12)};
  padding-right: ${rem(26)};
  margin-right: ${({ _open }) => (_open ? '0' : '-100vw')};

  ${min.s`
    margin: 0;
  `}
`;

const MenuItem: FC<MenuItemProps> = ({ index, link, open, title }: MenuItemProps) => (
  <StyledLink
    primary={true}
    role={'menuitem'}
    size={20}
    to={link}
    {...styledProps({ index, open })}
  >
    {title}
  </StyledLink>
);

export default memo(MenuItem);
