import React, { FC } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import BurgerButton from '../../ui/burger-button/burger-button';
import { rem } from '../../utils/converters';
import { MENU_HEIGHT, APP_CONTAINER_MAX_WIDTH } from '../../constants/layout';
import { min, useTransition } from '../../utils/mixins';
import useToggle from '../../hooks/use-toggle';
import { colors } from '../../utils/colors';
import { paths, siteName } from '../../constants/pages';
import { MENU_BUTTON, MENU_CLOSED, MENU_DESKTOP, MENU_OPEN } from '../../constants/z-index';
import TextLink from '../../ui/text-link/text-link';
import { AllSitePage, SitePage } from '../../types/query';

import MenuItem from './menu-item';

export const MENU_ITEMS_TO_RENDER = [
  paths.blog,
  paths.lab,
];

type ItemsContainerProps = {
  _open: boolean,
};

const Header = styled.header`
  display: flex;
  width: 100%;
  max-width: ${rem(APP_CONTAINER_MAX_WIDTH)};
  height: ${rem(MENU_HEIGHT)};
`;

const StyledTextLink = styled(TextLink)`
  display: block;
  margin: ${rem(30)} ${rem(24)};
`;

const ToggleButton = styled(BurgerButton)`
  ${min.s`
    display: none;
  `}
`;

const ItemsContainer = styled.div<ItemsContainerProps>`
  ${useTransition()}

  ${({ _open }) => `
    opacity: ${_open ? '1' : '0'};
    z-index: ${_open ? MENU_OPEN : MENU_CLOSED};
    pointer-events: ${_open ? 'auto' : 'none'};
  `}

  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  top: 0;
  background-color: ${colors.components.menu.background};
  transition-property: padding-top, opacity;

  ${min.s`
    z-index: ${MENU_DESKTOP};
    pointer-events: auto;
    left: 0;
    position: static;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: transparent;
    padding: 0;
    opacity: 1;
  `}
`;

const Menu: FC = () => {
  const [open, toggleOpen] = useToggle(false);
  const data: SitePage & AllSitePage = useStaticQuery(graphql`
    {
      sitePage(path: {eq: "/"}) {
        path
      }
      allSitePage {
        nodes {
          path,
          pageContext
        }
      }
    }
  `);

  const {
    sitePage: {
      path: homePath,
    },
    allSitePage: {
      nodes,
    },
  } = data;

  const menuItems = nodes.filter(({ path }) => MENU_ITEMS_TO_RENDER.includes(path));

  return (
    <Header>
      <StyledTextLink primary={true} size={24} to={homePath}>
        {siteName}
      </StyledTextLink>
      <ToggleButton
        label={`${open ? 'Close' : 'Open'} menu`}
        onClick={toggleOpen}
        open={open}
        size={MENU_HEIGHT}
        zIndex={MENU_BUTTON}
      />
      <ItemsContainer
        _open={open}
        onClick={toggleOpen}
        role={'menu'}
      >
        {menuItems.map(({ path, pageContext: { title } }, index) => (
          <MenuItem
            key={path}
            index={index}
            link={path}
            open={open}
            title={title!}
          />
        ))}
      </ItemsContainer>
    </Header>
  );
};

export default Menu;
