import { Tags } from '../types/tag';
import { tagColors } from '../utils/colors';

export const siteName = 'aquinq.dev';

export const tagsDictionary: Tags = {
  css: { id: 'css', tag: 'CSS', color: tagColors.css },
  gatsby: { id: 'gatsby', tag: 'Gatsby', color: tagColors.gatsby },
  jest: { id: 'jest', tag: 'Jest', color: tagColors.jest },
  react: { id: 'react', tag: 'React', color: tagColors.react },
  rtl: { id: 'rtl', tag: 'React Testing Library', color: tagColors.rtl },
};

/**
 * Must match the file location under the src/pages directory.
 */
export const paths = {
  home: '/',
  blog: '/blog/',
  lab: '/lab/',
};
