import React, { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

import Text from '../text/text';
import { GreyTextLink } from '../text-link/text-link';
import { colors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { useInsetShadow } from '../../utils/mixins';
import { AllSitePage } from '../../types/query';
import { compact } from '../../utils/array';
import { ArticlePages } from '../../types/article';
import { getMetadata } from '../../utils/metadata';
import { tagsDictionary } from '../../constants/pages';
import { PageContext } from '../../types/pages';

const getTitleForDynamicallyCreatedPage = (path: string): Pick<PageContext, 'title'> | undefined => {
  const lastURLSegment = compact(path.split('/')).pop();

  /**
   * The only way for lastURLSegment to be undefined would be when this function is
   * called on the home page ("/"). This is not possible since the home page contains metadata,
   * which means getMetadata would return a truthy value, and this function would not be called.
   */

  // if URL matches a tag index page
  const tag = tagsDictionary[lastURLSegment!];
  if (tag) return { title: tag.tag };

  return undefined;
};

const StyledList = styled.ol`
  margin: 0;
  padding: 0;
  white-space: nowrap;
  margin-bottom: ${rem(10)};
  padding-bottom: ${rem(10)};

  ${useInsetShadow()}
`;

const LinkContainer = styled.li`
  list-style: none;
`;

const getAllPaths = (pathname: string) => compact(pathname.split('/')).reduce<string[]>(
  (acc, cur, i) => [...acc, `${acc[i]}${cur}/`],
  ['/']
);

const Separator = () => (
  <Text color={colors.text.grey}>
    &#xa0;/&#xa0;
  </Text>
);

const Breadcrumbs: FC = () => {
  const { pathname } = useLocation();
  const data: AllSitePage & ArticlePages = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
          pageContext
        }
      }
      allMdx {
        nodes {
          fields {
            slug
          }
          frontmatter {
            description
            title
          }
        }
      }
    }
  `);

  const {
    allSitePage: {
      nodes: pages,
    },
    allMdx: {
      nodes: articles,
    },
  } = data;

  const paths = getAllPaths(pathname);

  if (paths.length === 1) return null; // do not render on home page

  return (
    <nav>
      <StyledList>
        {paths.map((path, i) => {
          const page = pages.find(({ path: pagePath }) => pagePath === path);
          if (!page) return null;

          /**
           * Only pages created from the "src/pages" directory have metadata in their context.
           * Articles have their metadata set in frontmatter inside the .mdx file.
           */
          const { title } = (
            getMetadata(page.pageContext, articles, path)
            ?? getTitleForDynamicallyCreatedPage(path)
            ?? {}
          );
          if (!title) return null;

          return (
            <LinkContainer key={page.path}>
              {i > 0 && <Separator />}
              <GreyTextLink to={page.path} color={colors.text.grey}>
                {title}
              </GreyTextLink>
            </LinkContainer>
          );
        })}
      </StyledList>
    </nav>
  );
};

export default Breadcrumbs;
