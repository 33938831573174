import { paths } from '../constants/pages';
import { Metadata } from '../types/metadata';

const metadata: Metadata = {
  /*
   * Meta data for the home page could be located in gatsby-config.js, under the field siteMetadata.
   * Placed here, they are imported for onCreatePage gatsby lifecycle function only, to be injected
   * in every page context, as long as the path of the page matches any key of this object.
   */
  [paths.home]: {
    title: 'Home',
    description: 'Antoine Quinquenel. A blog to share some code about React, React Native, Gatsby, CSS and more.',
  },
  [paths.blog]: {
    title: 'Blog',
    description: 'A blog to share some code about React, React Native, Gatsby, CSS and more.',
  },
  [paths.lab]: {
    title: 'Lab',
    description: 'A lab where you can find some random coding experiments',
  },
};

export default metadata;
