import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { colors } from '../colors';
import { rem } from '../converters';
import { useShadow } from '../mixins';
import { BASE_LINE_HEIGHT } from '../../constants/layout';

import prismThemes from './prism-themes';
import articleContainer from './article-container';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${prismThemes}
  ${articleContainer}

  html {
    line-height: normal;
  }

  html, body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
  
  body {
    color: ${colors.text.default};
    font-family: Quicksand-Regular, sans-serif;
    word-spacing: 0.1rem;
    line-height: ${BASE_LINE_HEIGHT};
  }
  
  #___gatsby, #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  .gatsby-resp-image-wrapper {
    ${useShadow()}

    border-radius: ${rem(10)};
    overflow: hidden;
    margin: ${rem(40)} auto;
  }
`;

export default GlobalStyle;
