import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { rem } from '../../utils/converters';
import Menu from '../menu/menu';
import Footer from '../footer/footer';
import ThemeSwitch from '../theme-provider/theme-switch';
import { APP_CONTAINER_MAX_WIDTH } from '../../constants/layout';
import { useBackgroundColor } from '../../utils/mixins';
import { colors } from '../../utils/colors';
import ThemeProvider from '../theme-provider/theme-provider';
import Breadcrumbs from '../../ui/breadcrumbs/breadcrumbs';
import GlobalStyle from '../../utils/global-style/global-style';
import SEO from '../seo';

type AppContainerProps = PropsWithChildren<{}>;

export const APP_CONTAINER_PADDING = 20;

const Container = styled.div`
  ${useBackgroundColor({ color: colors.box.background })}

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100%;
  width: 100%;
`;

const Main = styled.main`
  flex-grow: 1;
  width: 100%;
  max-width: ${rem(APP_CONTAINER_MAX_WIDTH)};
  box-sizing: border-box;
  padding: ${rem(APP_CONTAINER_PADDING)};
`;

const AppContainer: FC<AppContainerProps> = ({ children }: AppContainerProps) => (
  <ThemeProvider>
    <SEO />
    <Container>
      <GlobalStyle />
      <Menu />
      <Main>
        <ThemeSwitch />
        <Breadcrumbs />
        {children}
      </Main>
      <Footer />
    </Container>
  </ThemeProvider>
);

export default AppContainer;
