import React, { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

import { BASE_FONT_SIZE, LARGE_FONT_SIZE } from '../../constants/layout';
import { Styled } from '../../types/common';
import { colors } from '../../utils/colors';
import { rem } from '../../utils/converters';
import { useFont, useTextFill, useTransition } from '../../utils/mixins';
import { styledProps } from '../../utils/object';

type TextOwnProps = {
  bold?: boolean,
  center?: boolean,
  className?: string,
  color?: string,
  fill?: boolean,
  italic?: boolean,
  primary?: boolean,
  size?: number,
};

type StyledTextProps = Styled<Pick<TextOwnProps, 'bold' | 'center' | 'color' | 'fill' | 'italic' | 'primary' | 'size'>>;

const StyledText = styled.span.attrs<StyledTextProps>(({ children, _fill }) => (
  _fill
    ? { 'data-text': children }
    : null
))<StyledTextProps>`
  ${useTransition({ duration: 0.15 })}
  ${(props) => useFont({ bold: props._bold, italic: props._italic, primary: props._primary })}
  ${({ _fill }) => _fill && useTextFill()}

  color: ${(props) => props._color || colors.text.default};
  font-size: ${({ _primary, _size }) => rem(_size || (_primary && LARGE_FONT_SIZE) || BASE_FONT_SIZE)};
  text-align: ${({ _center }) => (_center ? 'center' : 'inherit')}
`;

export type TextProps = PropsWithChildren<TextOwnProps>;

const Text: FC<TextProps> = ({
  bold, center, children, className, color, fill, italic, primary, size,
}: TextProps) => (
  <StyledText
    {...styledProps({ bold, center, color, fill, italic, primary, size })}
    className={className}
  >
    {children}
  </StyledText>
);

export default Text;
