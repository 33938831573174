import { PageContext } from '../../types/pages';
import { ArticleNode } from '../../types/article';
import { getArticleFromPath } from '../query';

const containsMetadata = (pageContext: PageContext | undefined) => !!(
  pageContext && (
    // The GraphQL query that fetches the pageContext might not include all the fields.
    pageContext.title ?? pageContext.description
  )
);

export const getMetadata = (
  pageContext: PageContext | undefined,
  articles: ArticleNode[],
  path: string
): PageContext | undefined => {
  /**
   * Only pages created from the "src/pages" directory have metadata in their context.
   * Articles have their metadata set in frontmatter inside the .mdx file.
   */
  if (containsMetadata(pageContext)) return pageContext;

  const article = getArticleFromPath(articles, path);
  if (article) return article.frontmatter;

  return undefined;
};
