import { css } from 'styled-components';

import { CODE, LINE_HIGHLIGHT } from '../../constants/z-index';
import { darkColors } from '../colors';

const prismThemes = css`
  pre[class*="language-"],
  code[class*="language-"] {
    color: ${darkColors.text.default}; // 8becec, b4d4e4
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
  }
  pre[class*="language-"]::selection,
  code[class*="language-"]::selection,
  pre[class*="language-"]::mozselection,
  code[class*="language-"]::mozselection {
    text-shadow: none;
    background: #4c7290;
  }
  @media print {
    pre[class*="language-"],
    code[class*="language-"] {
      text-shadow: none;
    }
  }
  pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    background: ${darkColors.box.foreground};
  }
  :not(pre) > code[class*="language-"] {
    padding: .1em .3em;
    border-radius: .3em;
    color: ${darkColors.text.default}; // 8becec, b4d4e4
    background: #f9f2f4;
  }

  /********** Tokens **********/

  .namespace {
    opacity: .7;
  }
  .token.comment,
  .token.prolog,
  .token.doctype,
  .token.cdata {
    color: #9c9c9c;
  }
  .token.punctuation {
    color: #dedede;
  }
  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: #bb93ec;
  }
  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: #ffcc8a;
  }
  .token.operator,
  .token.entity,
  .token.url,
  .language-css .token.string,
  .style .token.string {
    color: #ee91a8;
  }
  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #1d9dea;
  }
  .token.function {
    color: #ff5c95;
  }
  .token.regex,
  .token.important,
  .token.variable {
    color: #ffcc8a;
  }
  .token.important,
  .token.bold {
    font-weight: bold;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }

  /********** Line highlighting **********/

  pre[data-line] {
    position: relative;
  }
  pre[class*="language-"] > code[class*="language-"] {
    position: relative;
    z-index: ${CODE};
  }
  .line-highlight {
    position: absolute;
    left: 0;
    right: -2.5rem; // width from useInsetShadow
    padding: inherit 0;
    background: #2a2b3d;
    box-shadow: inset 5px 0 0 #7e86f7;
    z-index: ${LINE_HIGHLIGHT};
    pointer-events: none;
    line-height: inherit;
    white-space: pre;
  }
`;

export default prismThemes;
