import styled from 'styled-components';

export const StylelessButton = styled.button.attrs({
  type: 'button',
})`
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
`;
