import React, { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';

import { rem } from '../../utils/converters';
import { StylelessButton } from '../../utils/components';
import { useBackgroundColor, useShadow } from '../../utils/mixins';
import { colors } from '../../utils/colors';
import { Styled } from '../../types/common';

const PADDING = 20;
const LINE_HEIGHT = 2;

type BurgerButtonOwnProps = {
  label?: string,
  open: boolean,
  size?: number,
  zIndex?: number,
};

export type BurgerButtonProps = ButtonHTMLAttributes<{}> & BurgerButtonOwnProps;

type LineProps = Styled<Pick<BurgerButtonOwnProps, 'open'>>;

type StyledButtonProps = {
  _size: number,
  _zIndex: number,
};

const StyledButton = styled(StylelessButton)<StyledButtonProps>`
  padding: ${rem(PADDING)};
  position: absolute;
  top: 0;
  right: 0;

  ${({ _size, _zIndex }) => `
    width: ${rem(_size)};
    height: ${rem(_size)};
    z-index: ${_zIndex};
  `}
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 50%;
`;

const Line = styled.div<LineProps>`
  ${useShadow()}
  ${useBackgroundColor({ color: colors.text.default })}

  position: absolute;
  box-sizing: border-box;
  left: 20%;
  width: 60%;
  height: ${rem(LINE_HEIGHT)};
  border: ${rem(1)} solid ${colors.text.default};
  border-radius: ${rem(999)};
`;

const Top = styled(Line)`
  top: 0;

  ${({ _open }) => _open && `
    top: calc(50% - ${rem(LINE_HEIGHT / 2)});
    left: -40%;
    width: 40%;
    transform: rotate(135deg);
    transform-origin: right 70%;
  `}
`;

const Middle = styled(Line)`
  top: calc(50% - ${rem(LINE_HEIGHT / 2)});

  ${({ _open }) => _open && `
    left: 35%;
  `}
`;

const Bottom = styled(Line)`
  top: calc(100% - ${rem(LINE_HEIGHT)});

  ${({ _open }) => _open && `
    top: calc(50% - ${rem(LINE_HEIGHT / 2)});
    left: -40%;
    width: 40%;
    transform: rotate(-135deg);
    transform-origin: right 30%;
  `}
`;

const BurgerButton: FC<BurgerButtonProps> = ({
  className,
  label,
  onClick,
  open,
  size = 80,
  title,
  zIndex = 0,
}: BurgerButtonProps) => (
  <StyledButton
    _size={size}
    _zIndex={zIndex}
    aria-label={label}
    className={className}
    onClick={onClick}
    title={title}
  >
    <Container>
      <Top _open={open} />
      <Middle _open={open} />
      <Bottom _open={open} />
    </Container>
  </StyledButton>
);

export default BurgerButton;
