import { css, FlattenSimpleInterpolation } from 'styled-components';

import { breakpoints, Breakpoint } from '../../constants/breakpoints';
import { timing } from '../../constants/styles';
import { fadeIn, fillBorderRadius } from '../keyframes';
import { colors, withOpacity } from '../colors';
import { rem } from '../converters';
import { INSET_SHADOW } from '../../constants/z-index';

type MediaQuery = (styles: TemplateStringsArray, ...args: any) => FlattenSimpleInterpolation;

type MediaQueries = Record<Breakpoint, MediaQuery>;

type TransitionArgs = {
  delay: number,
  duration: number,
  property: string,
};

type BackgroundColorArgs = {
  color: string,
};

export const min = (Object.keys(breakpoints) as Breakpoint[]).reduce((acc, cur) => {
  acc[cur] = (...styles) => css`
    @media(min-width: ${breakpoints[cur]}px) {
      ${css(...styles)}
    }
  `;
  return acc;
}, {} as MediaQueries);

export const max = (Object.keys(breakpoints) as Breakpoint[]).reduce((acc, cur) => {
  acc[cur] = (...styles) => css`
    @media(max-width: ${breakpoints[cur]}px) {
      ${css(...styles)}
    }
  `;
  return acc;
}, {} as MediaQueries);

export const useTransition = ({ delay = 0, duration = 0.4, property = 'all' }: Partial<TransitionArgs> = {}) => css`
  transition: ${duration}s ${timing};
  transition-property: ${property};
  ${delay ? `transition-delay: ${delay}s;` : ''}
`;

export const useFadeIn = ({ delay = 0 } = {}) => css`
  opacity: 0;
  animation: ${fadeIn} 0.6s ${timing} forwards;
  ${delay ? `animation-delay: ${delay}s;` : ''}
`;

export const useCodeTypography = () => css`
  font-family: 'Andale Mono', Monaco, monospace;
`;

export const useFont = ({ bold = false, italic = false, primary = false } = {}) => css`
  font-family: ${primary ? 'Acme-Regular, Tahoma' : 'inherit'};
  font-weight: ${bold ? '600' : '300'};
  font-style: ${italic ? 'italic' : 'inherit'};
  line-height: ${primary ? '1.25' : 'inherit'};
  word-spacing: ${primary ? rem(4) : 'inherit'};
`;

export const useTextFill = () => css`
  position: relative;

  &:hover {
    &::before {
      width: 100%;
      height: 100%;
      animation: ${fillBorderRadius} 0.6s linear forwards;
    }
  }

  &::before {
    ${useTransition({ duration: 0.6 })}

    content: attr(data-text);
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    overflow: hidden;
    color: ${colors.text.secondary};
    border-radius: 50%;
  }
`;

export const useScale = (scale: number = 1) => css`
  ${useTransition()}

  &:active, &:focus, &:hover {
    transform: scale(${scale});
  }
`;

export const useShadow = ({ clickable = false } = {}) => css`
  ${useTransition()}

  box-shadow: 0 ${rem(1)} ${rem(4)} ${colors.box.shadow};

  ${clickable && css`
    &:active, &:focus, &:hover {
      box-shadow: 0 ${rem(2)} ${rem(10)} ${colors.box.shadow_active};
    }
  `}
`;

export const useHideScrollbar = () => css`
  -ms-overflow-style: none; /* IE, Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const useHideScrollbarOnMobile = () => max.s`
  ${useHideScrollbar()}
`;

type UseInsetShadowArgs = {
  color?: string,
};

export const useInsetShadow = ({ color = colors.box.background }: UseInsetShadowArgs = {}) => css`
  ${useHideScrollbarOnMobile()}

  display: flex;
  position: relative;
  overflow: auto;

  &:after {
    ${useTransition()}

    content: '';
    position: sticky;
    right: 0;
    z-index: ${INSET_SHADOW};
    min-height: 100%;
    min-width: ${rem(40)};
    box-shadow: inset ${rem(-26)} 0 ${rem(26)} ${rem(-10)} ${color};
  }
`;

export const useForeground = ({ clickable = false, color = colors.box.foreground, scale = 1, shadow = false } = {}) => css`
  ${useTransition()}

  background-color: ${color};
  
  ${scale !== 1 && useScale(scale)}
  ${shadow && useShadow({ clickable })}
`;

export const useBackgroundColor = ({ color }: BackgroundColorArgs) => css`
  ${useTransition()}
  background-color: ${color};
`;

export const useReactiveBackgroundColor = ({ color }: { color: string }) => css`
  ${useTransition()}

  background-color: ${withOpacity(color, 1)};

  &:active, &:focus, &:hover, &::selection {
    background-color: ${withOpacity(color, 0.7)};
  }
`;
