export enum Themes {
  dark = 'dark',
  light = 'light'
}

export type Theme = Themes.dark | Themes.light;

export type ThemeContext = {
  switchTheme: () => void,
  theme: Theme,
};
